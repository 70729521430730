class Config {
    private static instance: Config;
    private env;
    private readonly env_original;
    // private settings:any;

    private constructor(env: any) {
        if (env !== null) {
            this.env = env;
            this.env_original = env;
        }
    }

    public static getInstance(env = null): Config {
        if (!Config.instance) {
            Config.instance = new Config(env);
        }
        return Config.instance;
    }

    public isApiTest():boolean {
        return this.getApiUrl()!=this.env.VITE_API_URL;
    }

    public hasInfoBoxesEnabled():boolean {
        return this.env.VITE_INFOBOXES==="true";
    }
    public hasFeatureCredits():boolean {
        return this.env.VITE_FEATURE_CREDITS==="true";
    }

    public isUrlConfig(): boolean {

        return location.href.indexOf("app/ik4kf884ax9") > 0
    }

    public getEnvOriginal() {
        return this.env_original;
    }

    public getApiUrl(): string {
        const stored_url = this.retrieveApiUrl();


        if (!stored_url) {
            return this.env.VITE_API_URL
        }

        if (!stored_url?.trim()) {
            return this.env.VITE_API_URL
        }
        return stored_url;
    }

    public storeApiUrl(apiUrl: string): void {
        localStorage.setItem('a8api_url', apiUrl);
    }

    public retrieveApiUrl(): string | null {
        return localStorage.getItem('a8api_url');
    }
}

export default Config;