const CONFIG_KEYS = {
  COMPANY_INITIAL_CREDITS: "company_initial_credits",
  COMPANY_MONTHLY_CREDITS: "company_monthly_credits",
  COMPANY_MONTHLY_CREDITS_CAP: "company_monthly_credits_cap",
  ADD_EMPLOYEE_COST: "add_employee_cost",
  ADD_JOBOFFER_COST: "add_jobOffer_cost",
  ADD_APPLICATION_COST: "add_application_cost",
  ADD_PROPOSAL_COST: "add_proposal_cost",
  FREE_COST: "free_cost",

  EMPLOYEE_EXPIRY_PERIOD: "employee_expiry_period",
  JOBOFFER_EXPIRY_PERIOD: "jobOffer_expiry_period",
  APPLICATION_EXPIRY_PERIOD: "application_expiry_period",
  PROPOSAL_EXPIRY_PERIOD: "proposal_expiry_period",
  LATEST_TERMS_UPDATE: "latest_terms_update",
  LATEST_PRIVACY_UPDATE: "latest_privacy_update",
  ACCEPT_APPLICATION_COST: "accept_application_cost",
};
export default CONFIG_KEYS;
