import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import AuthProvider from "./providers/auth.provider";
import { version } from "../package.json";
import { MantineProvider } from "@mantine/core";
import { autom8dealTheme } from "./providers/theme-autom8deal";
import { ModalsProvider } from "@mantine/modals";
import modals from "./providers/modals";
import { initLanguage } from "./helpers/initLanguage";
import { Authenticator, ThemeProvider } from "@aws-amplify/ui-react";
import AppComponents from "./AppComponents";
import theme from "./providers/theme-autom8deal-auth";

import * as Sentry from "@sentry/react";
import { NotificationProvider } from "./providers/notification.provider";
import { TourProvider } from "@reactour/tour";
import TagManager from 'react-gtm-module';
import ReactGA from "react-ga4";

declare let __BUILD_DATE__: string;

console.log(`autom8deal frontend`);
console.log(`version: ${version}`);
console.log(`apiUrl: ${import.meta.env.VITE_API_URL}`);
console.log(`env mode: ${import.meta.env.MODE}`);
console.log(`env_CFG: ${import.meta.env.VITE_CFG}`);
console.log(`env_INFOBOXES ${import.meta.env.VITE_INFOBOXES}`);
console.log(`build: ${__BUILD_DATE__}`);

if (!import.meta.env.DEV) {
  Sentry.init({
    dsn: "https://15fe51f1bba8f6b3cddd2b1664b0065f@o4507049991995392.ingest.us.sentry.io/4507051458560000",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Environment
    environment: import.meta.env.MODE,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      "https://api.autom8deal.com",
      /^https:\/\/api\.autom8deal\.com/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

TagManager.initialize({ gtmId: 'GTM-TTZPDNTC' });
ReactGA.initialize('AW-11479599697');

initLanguage().then(() => {
  ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
      <MantineProvider theme={autom8dealTheme} defaultColorScheme="light">
        <ModalsProvider modals={modals}>
          <ThemeProvider theme={theme}>
            <Authenticator components={AppComponents}>
              <AuthProvider>
                <NotificationProvider>
                  <TourProvider steps={[]} showCloseButton={false} padding={10}>
                    <App />
                  </TourProvider>
                </NotificationProvider>
              </AuthProvider>
            </Authenticator>
          </ThemeProvider>
        </ModalsProvider>
      </MantineProvider>
    </React.StrictMode>
  );
});
