import _isArray from "lodash/isArray";
import _get from "lodash/get";
import showReloadModal from "./showReloadModal";
import { notifyError, notifySuccess } from "./notify.ts";
import FetchFnProps from "src/types/FetchFnProps";
import Application from "../classes/Application";

const getErrorMessage = (err: Error): string =>
  _get(err, "reason") ?? _get(err, "message", err.toString());

function fetchFn(args: FetchFnProps, opts?: { silent: boolean }) {
  const cfg = Application.getInstance().getConfig();
  const {
    url,
    method = "GET",
    body,
    token,
    headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    extraHeaders = {},
  } = args ?? {};

  if (!token) {
    notifyError("User not authenticated", 401);
    return Promise.reject(new Error("401"));
  }

  if (!url) {
    notifyError("Invalid request", 400);
    return Promise.reject(new Error("400"));
  }

  const mergedHeaders = {
    ...headers,        // default headers
    ...extraHeaders,  // custom headers (override or add)
    Authorization: `Bearer ${token}`,
  };

  const options = {
    method,
    // credentials: "include",
    headers: mergedHeaders,
    body: JSON.stringify(body),
  };

  return fetch(url, options).then(async (response) => {
    if (response.ok) {
      if (!opts?.silent) {
        notifySuccess(method);
      }
      if (_get(headers, "Accept") === "application/json") {
        return response.json();
      }
      return response;
    }

    switch (response.status) {
      case 497:
      case 498: {
        if(cfg.isUrlConfig()) {
          return;
        }
        // showReloadModal("expired_token", "expired_token_desc");
        window.location.reload();
        break;
      }
      // case 498: {
      //   showReloadModal("invalid_token", "invalid_token_desc");
      //   break;
      // }
      case 401:
      case 499: {
        showReloadModal("missing_token", "missing_token_desc");
        break;
      }

      case 403: {
        notifyError("unauth", response.status);
        break;
      }

      // case 500: {
      //   notifyError("server error", response.status);
      //   break;
      // }

      default: {
        const errorJson = (await response.json()) || null;
        if (errorJson) {
          console.log(errorJson);
          if (_isArray(errorJson)) {
            errorJson.map((e) => notifyError(getErrorMessage(e), 400));
          } else {
            notifyError(getErrorMessage(errorJson), 400);
          }
        }
        const errorMessage = response.toString() || "error";
        notifyError(errorMessage, response.status);
      }
    }

    return Promise.reject(new Error(`${response.status}`));
  });
}

export default fetchFn;
