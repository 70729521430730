// this will need a rewrite
// used to keep outside the modal handling

class ConfirmHandler {
    private submitHandler: Function | null = null;
    private static instance: ConfirmHandler | null = null;

    private constructor() {}
    public static getInstance(): ConfirmHandler {
        if (!ConfirmHandler.instance) {
            ConfirmHandler.instance = new ConfirmHandler();
        }
        return ConfirmHandler.instance;
    }

    public getSubmitHandler(): Function | null {
        return this.submitHandler;
    }
    public setSubmitHandler(submitHandler: Function): void {
        this.submitHandler = submitHandler;
    }

    public executeSubmitHandler(): void {
        if (this.submitHandler) {
            this.submitHandler();
        } else {
            console.error('Submit handler is not set');
        }
    }
}

export default ConfirmHandler;