import { TradeType } from "src/types/TradeType";
import useConfig from "./useConfig";
import Config from "../classes/application/Config";

function useTradeTypeValues(type: TradeType) {
  const config = useConfig();
  const label = type + "_label";
  const desc = type + "_desc";
  let cost = config.get(type + "_cost") ?? 0;
  const cfg = Config.getInstance();
  
  if(!cfg.hasFeatureCredits()) {
    cost=0;
  }

  return {
    label,
    cost,
    desc,
  };
}

export default useTradeTypeValues;
