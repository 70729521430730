import {
  IconAffiliate,
  IconBriefcase,
  IconStar,
  IconUser,
} from "@tabler/icons-react";
import { LinkProps } from "src/types/LinkProps.type";

export default [
  {
    title: "developers",
    label: "manage_employees",
    Icon: IconUser,
    to: "/app/section/employees",
    classes: "tour_employees",
  },
  {
    title: "job_offers",
    label: "manage_job_offers",
    Icon: IconBriefcase,
    to: "/app/section/jobs",
    classes: "tour_jobs",
  },
  // {
  //   title: "credits",
  //   label: "manage_credits_info",
  //   Icon: IconBuildingBank,
  //   to: "/app/credits",
  // },
  {
    title: "matches",
    label: "matches",
    Icon: IconAffiliate,
    to: "/app/section/matches",
  },
  // {
  //   title: "activity",
  //   label: "review_account_history",
  //   Icon: IconList,
  //   to: "/app/section/activity",
  // },
  // {
  //   title: "Applications Received",
  //   label: "Watch the applications received",
  //   Icon: IconMailbox,
  //   to: "/app/section/applications_received",
  // },
  // {
  //   title: "Applications Sent",
  //   label: "Review your sent applications",
  //   Icon: IconSend,
  //   to: "/app/section/applications_sent",
  // },
  // {
  //   title: "Proposal Received",
  //   label: "Watch the proposals received",
  //   Icon: IconMailDown,
  //   to: "/app/section/proposals_received",
  // },
  // {
  //   title: "Proposal Sent",
  //   label: "Review your posted proposals",
  //   Icon: IconMailUp,
  //   to: "/app/section/proposals_sent",
  // },
  {
    title: "admins",
    label: "Manage your team",
    Icon: IconStar,
    to: "/app/section/admins",
  },
] as LinkProps[];
