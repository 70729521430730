import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import { Outlet } from "react-router-dom";
import useRegisterIfNeeded from "src/hooks/useRegisterIfNeeded";
import Application from "../classes/Application";

function RootLayout() {
    if(!Application.getConfig().isUrlConfig()) {
        useRegisterIfNeeded();
    }

  return (
    <>
      <Outlet />
    </>
  );
}

export default RootLayout;
