import Employee from "src/types/Employee.type";
import SimpleTable from "./SimpleTable";
import EmployeeTableRow from "./rows/EmployeeTable.Row";
//import Confirm from "../ui/widget/Confirm/Confirm";

interface Props {
  employees?: Employee[];
}

const cols = ["", "name", "level", "fields", "availability", "status", ""];

export function EmployeeTable({ employees = [] }: Props) {
  return (
       <>
        <SimpleTable<Employee>
          rows={employees}
          cols={cols}
          Row={EmployeeTableRow}
          section="employee"
        />
      </>
  );
}
