//import { useState } from "react";
import Modal from "../Modal/Modal";
import "./Confirm.css";
import { useTranslation } from "react-i18next";

interface ConfirmProps {
    isOpen?: boolean;           // Optional
    title?: string;             // Optional
    message?: string;           // Optional
    onConfirm: () => void;     // Optional
    onCancel?: () => void;      // Optional
    closeModal?: () => void;    // Optional
    txtConfirm?: string;    // Optional
    txtCancel?: string;    // Optional
}

function Confirm({
                     isOpen,
                     title,
                     message,
                     onConfirm,
                     onCancel,
                     closeModal,
                     txtConfirm,
                     txtCancel,
                 }: ConfirmProps) {
    const handleConfirm = () => {
        onConfirm();
        if(closeModal) {
            closeModal();
        }
    };
    const { t } = useTranslation();

    const handleCancel = () => {
        if (onCancel) onCancel();
        if(closeModal) {
            closeModal();
        }
    };
//  title="Oodofo"
    if(!isOpen) {
        isOpen = false;
    }
    let Confirm= t("confirm");
    let Cancel= t("cancel");
    if(txtConfirm) {
        Confirm= txtConfirm;
    }
    if(txtCancel) {
        Cancel= txtCancel;
    }
    Confirm = Confirm.charAt(0).toUpperCase() + Confirm.slice(1);

    Cancel = Cancel.charAt(0).toUpperCase() + Cancel.slice(1);
    if(title) {

    title= title.charAt(0).toUpperCase() + title.slice(1);
    }

    return (
        <Modal isOpen={isOpen} onClose={handleCancel}>
            <div className="confirm-content">
                <h3>{title}</h3>
                <p>{message}</p>
                <div className="confirm-buttons">

                    <button className="confirm-yes" onClick={handleConfirm}>
                        {Confirm}
                    </button>
                    <button className="confirm-no" onClick={handleCancel}>
                        {Cancel}
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default Confirm;
