import React, { useState } from 'react';
import Modal from '../Modal/Modal';
import './InfoBox.css';
import InfoBoxIcon from "../../icon/InfoBoxIcon";
import Config from "../../../../classes/application/Config";

interface InfoBoxProps {
    title: string;
    message: string;
}

const InfoBox: React.FC<InfoBoxProps> = ({ title, message }) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const cfg = Config.getInstance();
    const enabled=cfg.hasInfoBoxesEnabled();
/*
    <svg className="infobox-trigger" onClick={toggleModal} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="tabler-icon tabler-icon-info-circle">
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="12" y1="16" x2="12" y2="12"></line>
        <line x1="12" y1="8" x2="12.01" y2="8"></line>
    </svg>
  */
    return (
        <>
           <Modal isOpen={isModalOpen} onClose={toggleModal}>
                <div className="infobox-content">
                    <h3>{title}</h3>
                    <p>{message}</p>
                    <button  className="infobox-close" onClick={toggleModal}>
                        Close
                    </button>
                </div>

            </Modal>
            {enabled && (
                <InfoBoxIcon onClick={toggleModal}></InfoBoxIcon>
            )}
        </>

    );
};

export default InfoBox;