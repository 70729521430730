import Actions from "src/components/Actions";
import Loader from "src/components/Loader";
import ProposalTable from "src/components/tables/ProposalTable";
import { useGET } from "src/hooks/useApi";
import Proposal from "src/types/Proposal.type";

const ListProposalReceived = () => {
  const { data, isLoading, error } = useGET<Proposal[]>(
    "account/company/proposals_received"
  );

  return (
    <Loader isLoading={isLoading} error={error} hasData={!!data}>
      <Actions title="proposals_received" />
      <ProposalTable mode={"received"} data={data} />
    </Loader>
  );
};

export default ListProposalReceived;
