import Config from "./application/Config";

class Application {
    private static instance: Application;
    private readonly config: Config;
    protected env:any;

    private constructor(env = null) {
        if (env !== null) {
            this.env = env;
        }
        this.config = Config.getInstance(env);
    }

    public static getInstance( env:any = null): Application {
        if (!Application.instance) {
            Application.instance = new Application(env);
        }
        return Application.instance;
    }

    public static getConfig(): Config {
        return Application.getInstance().getConfig();
    }

    public getConfig(): Config {
        return this.config;
    }
}

export default Application;