import React from 'react';

interface InfoBoxIconProps {
    size?: number; // Optional size prop
    color?: string; // Optional color prop
    onClick?: () => void; // Optional onClick event handler
}

const InfoBoxIcon: React.FC<InfoBoxIconProps> = ({ size = 42, color = "#2196F3", onClick }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            style={{ display: "block", width: `${size}px`, height: `${size}px`, color: color }}
            onClick={onClick} // Attach the onClick handler
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
        </svg>
    );
};

export default InfoBoxIcon;

/*
<svg
    onClick={toggleModal}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    width="29"
    height="29"
    style={{
        //border: "1px solid black",
        padding: 0,
        margin: 0,
        display: "block", // Ensures no extra inline spacing
    }}
>
    <circle cx="32" cy="32" r="25" fill="#2196F3" />
    <text
        x="50%"
        y="50%"
        fontSize="22"
        textAnchor="middle"
        alignmentBaseline="central"
        fill="white"
        fontFamily="Arial, sans-serif"
        fontWeight="bold"
        font-style="italic"
    >
        i
    </text>
</svg>

<svg
    onClick={toggleModal}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    width="32"
    height="32"
    style={{
        padding: 0,
        margin: 0,
        display: "block", // Ensures no extra inline spacing
    }}
>
    <circle cx="32" cy="32" r="28" fill="#2196F3" />
    <text
        x="50%"
        y="50%"
        fontSize="32"
        textAnchor="middle"
        alignmentBaseline="central"
        fill="white"
        fontFamily="Arial, sans-serif"
        fontWeight="900" // Increased font weight for more boldness
        fontStyle="italic"
    >
        i
    </text>
</svg>

<svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    style={{ width: "24px", height: "24px", color: "#2196F3" }}
>
    <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    />
</svg>

<svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    style={{ width: "24px", height: "24px", color: "#2196F3" }}
>
    <path d="M12 2a10 10 0 100 20 10 10 0 000-20zM12 14v4m0-10v4" />
</svg>
<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="currentColor"
    className="bi bi-info-circle"
    viewBox="0 0 16 16"
    style={{ color: "#2196F3" }}
>
    <path d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zM8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0z" />
    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 .876-.252 1.03-.598.052-.122.115-.354.173-.59l.451-2.076c.07-.32.116-.367.402-.412l.082-.016.07-.38zM8 4.75a.875.875 0 1 1 0-1.75.875.875 0 0 1 0 1.75z" />
</svg>

<svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="#2196F3"
    style={{ width: "24px", height: "24px" }}
>
    <circle cx="12" cy="12" r="10" />
    <text
        x="50%"
        y="50%"
        textAnchor="middle"
        alignmentBaseline="middle"
        fill="white"
        fontSize="12"
        fontWeight="bold"
    >
        i
    </text>
</svg>
*/
