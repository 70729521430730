import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import Actions from "src/components/Actions";
import Loader from "src/components/Loader";
import MatchesTable from "src/components/tables/MatchesTable";
import { useGET } from "src/hooks/useApi";
import Matches from "src/types/Matches.type";
import { useMemo } from "react";

const ListMatches = () => {
  const { data, isLoading, error } = useGET<Matches>("account/company/matches");
  const listFrom = _map(data?.from, "CompanyFrom");
  const listTo = _map(data?.to, "CompanyTo");
  const { list, isEmpty } = useMemo(
    () => ({
      list: [...listFrom, ...listTo],
      isEmpty: _isEmpty(listFrom) && _isEmpty(listTo),
    }),
    [listFrom, listTo]
  );

  return (
    <Loader isLoading={isLoading} error={error} hasData={!isEmpty}>
      <Actions title="matches_confirmed" />
      <MatchesTable data={list} />
    </Loader>
  );
};

export default ListMatches;
