import React, { useState } from 'react';
import Application from "../../classes/Application";
import { useLocation } from "react-router-dom";

export default function ApiCfg() {
    const cfg = Application.getInstance().getConfig();
    const [apiUrl, setApiUrl] = useState(cfg.getApiUrl());


    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const urlValue = queryParams.get("url"); // Gets the value of 'url'

    if(urlValue) {
        cfg.storeApiUrl(urlValue);
        window.location.href="/app";

    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setApiUrl(e.target.value);
    };

    const handleSave = () => {
        if (apiUrl) {
            cfg.storeApiUrl(apiUrl);
            alert("now using "+apiUrl);
            window.location.href="/app";

        } else {
        }
    };

    const handleReset = () => {
        setApiUrl('');
        cfg.storeApiUrl('');
        setApiUrl(cfg.getApiUrl());
        alert("now using default")
        window.location.href="/app";
    };

    return (
        <div>
            <h2>Api</h2>
            <input
                style={{ width: '300px' }}
                type="text"
                value={apiUrl}
                onChange={handleChange}
                placeholder="url"
            />
            <button onClick={handleSave}>Save</button>
            <div>
                <br/>
                <br/>
                <br/>
                <button onClick={handleReset}>Reset to default</button>
            </div>
        </div>
    );
}
