import { useTranslation } from "react-i18next";
import _map from "lodash/map";
import { Flex, Paper, Title } from "@mantine/core";
import ActionButton from "./ActionButton";
import useIsMobile from "src/hooks/useIsMobile";
import Confirm from "./ui/widget/Confirm/Confirm";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ConfirmHandler from "../classes/ui/ConfirmHandler";

interface Action {
  to?: string;
  label?: string;
  onClick?: (event?: React.MouseEvent<HTMLElement>) => void;  // Make event optional
  variant?: string;
  color?: string;
  disabled?: boolean;
  Icon?: React.FC;
  tooltip?: string;
  cost?: number;
  hasConfirm?: boolean;
  confirmTitle?: string;
  confirmMessage?: string;
}


interface Props {
  title?: string;
  center?: boolean;
  isReady?: boolean;
  actions?: (Action | null)[];
  hasConfirm?: boolean,
  confirmTitle?: string,
  confirmMessage?: string ,
}



const Actions = (props: Props) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const direction = isMobile ? "column" : "row";
  const { actions = [], title = "", center, isReady = true } = props;
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [confirmTo, setConfirmTo] = useState("");
  const [confirmCallback, setConfirmCallback] = useState(null);

//  const [confirmAction, setConfirmAction] = useState<(() => void) | null>(null);

  const [confirmTitle, setConfirmTitle] = useState<string>("");
  const [confirmMessage, setConfirmMessage] = useState<string>("");
  // alert(JSON.stringify(props));
  console.log(isConfirmOpen);

  const style = {
    background: title
      ? "linear-gradient(90deg, var(--mantine-color-primary-filled), transparent)"
      : "transparent",
    boxShadow: "none",
    border: "none",
  };

  const handleConfirm = () => {

    let confhdl=ConfirmHandler.getInstance();
    console.log(confirmCallback);
    if(confhdl) {
      let subh = confhdl.getSubmitHandler();
      if(subh) {
        confhdl.executeSubmitHandler();
      }
    }
    if(confirmTo) {
      setConfirmCallback(null);
      navigate(confirmTo);
    }
    setIsConfirmOpen(false); // Close confirmation modal
  };

  const handleCancel = () => {
    setIsConfirmOpen(false);
  };

  const openConfirm = (submitHandler:any) => {
    setIsConfirmOpen(true);
    ConfirmHandler.getInstance().setSubmitHandler(submitHandler);
  }
  return (
    <Paper c="white" px="md" py="xs" mb="xl" style={style}>

      <Confirm
          // closeModal={}
          isOpen={isConfirmOpen}
          title={confirmTitle}
          message={confirmMessage}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
      />
      <Flex justify="space-between" align="center" direction={direction}>
        <Flex direction="column" my="md">
          <Title order={4} tt="uppercase">
            {t(title)}
          </Title>
        </Flex>
        <Flex
          direction={direction}
          justify={center || isMobile ? "center" : "end"}
          gap="sm"
          wrap="wrap"
        >
          {_map(actions, (action) => {
            if (!action) {
              return null;
            }
            let {
              to = "/",
              label,
              variant = "primary",
              onClick,
              color,
              disabled = false,
              Icon = undefined,
              tooltip,
              cost = 0,
            } = action;


            // @todo check qui
            //     alert("loop"+JSON.stringify(action));
            //     alert(action.hasConfirm);
            //alert(JSON.stringify(action.hasConfirm))
            let hasConfirm = action.hasConfirm

            if(hasConfirm && isReady) {


              // we have to use confirm
              if(!onClick) {

                onClick = function () {
                  setConfirmTitle(confirmTitle)
                  setConfirmMessage(confirmMessage)
                  setConfirmTo(to);
                  openConfirm(null);

                }
              }
              else {

                const submitH=onClick;
                onClick = function () {
                  if (action.confirmTitle != null) setConfirmTitle(action.confirmTitle);
                  if (action.confirmMessage != null) setConfirmMessage(action.confirmMessage);

                  openConfirm(submitH);
                }

                /*

                alert(onClick);
                hasConfirm=false;
                 */
              }

            }
            return (
              <ActionButton
                key={to + label}
                to={to}
                label={label}
                variant={variant}
                onClick={onClick}

                color={color}
                disabled={disabled}
                Icon={Icon}
                tooltip={tooltip}
                cost={cost}
                isReady={isReady}
               // last added
                hasConfirm={hasConfirm}
                confirmTitle={confirmTitle}
                confirmMessage={confirmMessage}
              />
            );
          })}
        </Flex>
      </Flex>
    </Paper>
  );
};

export default Actions;
