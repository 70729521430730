import { Stack, Title } from "@mantine/core";
import {
  IconBuildingBank,
  IconShield,
  IconUserUp,
  IconAdjustmentsHorizontal,
  IconUserPlus,
} from "@tabler/icons-react";
import SettingsGeneral from "./Settings.General";
import SettingsBilling from "./Settings.Billing";
import SettingsAccount from "./Settings.Account";
import SettingsRoles from "./Settings.Roles";
import { useTranslation } from "react-i18next";
import Tabs from "src/components/Tabs";
import SettingsInvites from "./Settings.Invites";
import Config from "../classes/application/Config";
import iconMaps from "../constants/iconMaps";


const cfg = Config.getInstance();


const tabs:any[] = [];

tabs.push({
  id: "general",
  title: "general",
  Icon: <IconAdjustmentsHorizontal />,
  content: <SettingsGeneral />,
});

tabs.push({
  id: "roles",
  title: "roles",
  Icon: <IconUserUp />,
  content: <SettingsRoles />,
});

tabs.push({
  id: "invites",
  title: "invites",
  Icon: <IconUserPlus />,
  content: <SettingsInvites />,
});


tabs.push({

  id: "invites_request",
  label: "invites_request",
  to: "/app/section/invites",
  Icon: iconMaps.inviteRequest,
  title: "invites_request",
});

tabs.push({
  id: "account",
  title: "account",
  Icon: <IconShield />,
  content: <SettingsAccount />,
});

if(cfg.hasFeatureCredits()) {
  tabs.push(
  {
       id: "billing",
       title: "billing",
       Icon: <IconBuildingBank />,
       content: <SettingsBilling />,
  }
  );

}

export default function Settings() {
  const { t } = useTranslation();

  return (
    <Stack p="md">
      <Title p="md">{t("settings")}</Title>
      <Tabs tabs={tabs} />
    </Stack>
  );
}
