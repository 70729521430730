import _get from "lodash/get.js";
import {
  Stack,
  TextInput,
  Title,
  NumberInput,
  Box,
  TagsInput,
  Switch,
  InputLabel,
  Input,
} from "@mantine/core";
import _omit from "lodash/omit.js";
import { DatePickerInput } from "@mantine/dates";
import FormSection from "src/components/forms/FormSection";
import Employee from "src/types/Employee.type";
import Actions from "../Actions";
import { useForm } from "@mantine/form";
import { EmployeeValidate } from "src/pages/data/validates/employee.validate";
import { useState } from "react";
import ImagePreview from "../ImagePreview";
import CustomSelect from "../CustomSelect";
import useFormActions from "../../hooks/useFormActions";
import handleFileUpload from "src/utils/handleFileUpload";
import useUnloadAlert from "src/hooks/useUnloadAlert";
import { useTranslation } from "react-i18next";
import SkillSelect from "../SkillSelect";
import { META_FIELDS } from "src/constants/metaFields.const";
import DescriptionInput from "src/components/DescriptionInput";
import InputFile from "../InputFile";

interface Props {
  title: string;
  onSubmit: (values: Partial<Employee>, file?: File) => void;
  initialValues?: Partial<Employee>;
  costId?: string;
  expiryId?: string;
}
const now = new Date();

export default function EmployeeForm({
  title,
  initialValues = {},
  onSubmit = () => {},
  costId,
  expiryId,
}: Props) {
  const [file, setFile] = useState<File>();
  const [available, setAvailable] = useState<boolean>(true);
  const form = useForm<Partial<Employee>>({
    validateInputOnChange: true,
    initialValues,
    validate: EmployeeValidate,
    transformValues: (values) => ({
      ..._omit(values, ["Applications", "Proposal", "Company", ...META_FIELDS]),
      birthYear: Number(_get(values, "birthYear")),
      hourlyRate: Number(_get(values, "hourlyRate")),
    }),
  });
  useUnloadAlert();
  //alert("fflfk");
  const formActions = useFormActions(form, onSubmit, file, costId, expiryId);
  const { t } = useTranslation();

  const handleFileChange = handleFileUpload(setFile);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setAvailability = (e: any) => {
    form.setFieldValue("availableFrom", null);
    form.setFieldValue("availableTo", null);
    setAvailable(e?.currentTarget?.checked);
  };

  return (
    <Stack maw={1000} m="auto">
      <Title>{t(title)}</Title>

      <FormSection title="pro_pic">
        <Box m="auto">
          <ImagePreview value={file} defaultValue={form.values.profilePic} />
          <InputFile onChange={handleFileChange} />
        </Box>
      </FormSection>
      <FormSection title="public_profile">
        <TextInput
          my="md"
          required
          label={t("name")}
          placeholder={t("name")}
          {...form.getInputProps("name")}
        />

        <TextInput
          my="md"
          required
          label={t("surname")}
          placeholder={t("surname")}
          {...form.getInputProps("surname")}
        />

        <NumberInput
          required
          my="md"
          step={1}
          min={new Date().getFullYear() - 80}
          max={new Date().getFullYear() - 17}
          label={t("birth_year")}
          placeholder={t("birth_year")}
          {...form.getInputProps("birthYear")}
        />
      </FormSection>
      <FormSection title="details">
        <CustomSelect
          required
          label="job_title"
          source="roles"
          inputProps={form.getInputProps("jobTitle")}
        />

        <CustomSelect
          single
          required
          label="level"
          source="levels"
          inputProps={form.getInputProps("level")}
        />

        <CustomSelect
          single
          required
          source="hourlyRates"
          label={"hourly_rate_long"}
          inputProps={form.getInputProps("hourlyRate")}
        />

        <InputLabel my="md">{t("costs_included")}</InputLabel>
        <Switch
          mb="md"
          checked={!!form.values?.costsIncluded}
          onChange={() =>
            form.setFieldValue("costsIncluded", !form.values?.costsIncluded)
          }
        />

        <CustomSelect
          required
          source="fields"
          inputProps={form.getInputProps("fields")}
        />

        <InputLabel mt="xs">{t("skills")} *</InputLabel>
        <Box m="md">
          <SkillSelect
            values={form.values.skills ?? []}
            setValues={(v) => {
              form.setFieldValue("skills", v);
            }}
          />
        </Box>

        <DescriptionInput
          label={t("desc_employee")}
          {...form.getInputProps("desc")}
        />
      </FormSection>

      <FormSection title="availability_details">
        {form?.values?.residentialNation}
        <CustomSelect
          single
          label="residential_nation"
          source="countries"
          inputProps={form.getInputProps("residentialNation")}
        />
        <InputLabel mt="xs">{t("residential_city")}</InputLabel>
        <Input {...form.getInputProps("residentialCity")} />
        <CustomSelect
          required
          label="available_to_work_in"
          source="zones"
          inputProps={form.getInputProps("zones")}
        />
        <CustomSelect
          required
          source="languages"
          inputProps={form.getInputProps("languages")}
        />
        <CustomSelect source="visas" inputProps={form.getInputProps("visa")} />
        <InputLabel my="md">{t("immediate_availability")}</InputLabel>
        <Switch defaultChecked checked={available} onChange={setAvailability} />
        {available || (
          <>
            <DatePickerInput
              mt="md"
              minDate={now}
              label={t("available_from")}
              placeholder={t("pick_date")}
              {...form.getInputProps("availableFrom")}
            />
            <DatePickerInput
              mt="md"
              minDate={now}
              label={t("available_to")}
              placeholder={t("pick_date")}
              {...form.getInputProps("availableTo")}
            />
          </>
        )}
      </FormSection>

      <FormSection title="formation">
        <TagsInput
          my="md"
          label={t("certifications")}
          placeholder={t("press_enter_to_submit")}
          {...form.getInputProps("certifications")}
        />
        <TagsInput
          my="md"
          label={t("trainings")}
          placeholder={t("press_enter_to_submit")}
          {...form.getInputProps("training")}
        />
      </FormSection>

      <Actions actions={formActions} isReady={form.isValid()} />
    </Stack>
  );
}
