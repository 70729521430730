import _includes from "lodash/includes";
import _startsWith from "lodash/startsWith";
import { Button } from "@mantine/core";
import { useLocation, useNavigate } from "react-router-dom";
import { IconChevronLeft } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

const shouldHide = (path: string): boolean => {
  return _includes(["/app", "app/view/job", "/app/view/employee"], path);
};

const shouldGoBack = (path: string): boolean => {
  return !_startsWith(path, "/app/section/");
};

const NavigationBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const url = useLocation();
  const path = url.pathname || "";

  if (shouldHide(path)) {
    return null;
  }

  const goBack = () => {
    shouldGoBack(path) ? navigate(-1) : navigate("/app");
  };


  const isVisible = window.innerWidth > 600; // Example condition for visibility

  return (
      <div>
        <div style={{ height: "50px" }}>
          <Button
            variant="subtle"
            aria-label="Back"
            color="default"
            onClick={goBack}
            style={{
              visibility: isVisible ? "visible" : "hidden",
              opacity: isVisible ? 1 : 0,
              pointerEvents: isVisible ? "auto" : "none",
            }}
            className={isVisible ? "" : "hidden-placeholder"}
            visibleFrom="sm"
            my="xs"
        >
          <IconChevronLeft/>
          {t("back")}
        </Button>
        </div>

      </div>


        );
};

export default NavigationBar;
